import React, { MouseEventHandler } from 'react';
//components
import { Box, Flex, Text } from '@chakra-ui/react';
import { Button } from '../../../../../components/Button';
import { OnboardingSteps } from '../../../../../components/OnboardingSteps';
import { CustomTonWalletButton } from '../../../../../components/TonWalletButton';

interface IBuyNftOnboardingProps {
  onBuyNft?: MouseEventHandler<HTMLButtonElement>;
}

const steps = [
  { label: 'Go to Getgems', icon: '🌐' },
  { label: 'Connect your wallet', icon: '🔗' },
  { label: 'Select your desired NFT', icon: '💎' },
  { label: 'Complete the purchase', icon: '💳' },
  { label: 'Return to stake your NFT and earn bonuses', icon: '🎁' },
];

export const BuyNftOnboarding: React.FC<IBuyNftOnboardingProps> = ({ onBuyNft }) => (
  <Flex flexGrow='1' flexDirection='column' justifyContent='space-between' height='100%'>
    <Box paddingTop='8px'>
      <Flex flexDirection='column' alignItems='center' gap='19px'>
        <CustomTonWalletButton />
        <Text
          fontFamily='heading'
          maxWidth='340px'
          fontSize='32px'
          position='relative'
          textAlign='center'
          lineHeight='1'
          padding='16px'
        >
          How to buy NFTs
        </Text>
      </Flex>
      <OnboardingSteps steps={steps} />
    </Box>
    {onBuyNft && (
      <Box padding='8px 16px'>
        <Button fullWidth width='100%' onClick={onBuyNft}>
          Buy on market
        </Button>
      </Box>
    )}
  </Flex>
);
