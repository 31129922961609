import React from 'react';
import { useTonConnectModal } from '@tonconnect/ui-react';
//components
import { GifTextBlock } from '../../../../../components/GifTextBlock';
import { Button } from '../../../../../components/Button';
//styles
import bagGif from '../../../assets/bag.gif';
import styles from './styles.module.scss';

export const ConnectWallet: React.FC = () => {
  const { open } = useTonConnectModal();

  return (
    <div className={styles.wrapper}>
      <GifTextBlock
        img={bagGif}
        title='Grow your best friends'
        subTitle='Connect wallet if you have NFT or purchase access to the game'
      />
      <div className={styles.connectButton}>
        <Button fullWidth onClick={open}>
          Connect wallet
        </Button>
      </div>
    </div>
  );
};
