import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ConnectedWallet } from '@tonconnect/ui-react';
import { AxiosError } from 'axios';
import useSWR from 'swr';
// components
import { FullPageLoader } from '../../../components/FullPageLoader';
import { BuyNftOnboarding, StakingBlock } from '../components/Staking';
import { BonusDrawer, NftDrawer } from '../components/Staking/Drawer';
// other
import { API, fetcher } from '../../../api';
import { useTonNFTApi } from '../hooks';
import { useTonConnect } from '../../../hooks';
import { openLink } from '../../../utils/openTelegramLink';
import { getBonuses } from '../utils/stakingHelpers';
import { IUpgrades } from '../../Home/types';
import { Network } from '../../../types';

interface IStakingProps {
  wallet: ConnectedWallet;
}

export const Staking: React.FC<IStakingProps> = ({ wallet }) => {
  const { network } = useTonConnect();
  const {
    NFTs: { collection = [] },
    loading: isLoadingNFTs,
  } = useTonNFTApi(
    process.env.REACT_APP_NFT_ADDRESS as string,
    wallet.account.address,
    network?.name as Network,
    {
      showLoader: true,
      checkNftOnFirstLoadin: true,
      onlyFirstLoading: true,
    }
  );

  const {
    isLoading: isLoadingUpgrades,
    data: upgrades,
    error: upgradesError,
  } = useSWR<IUpgrades, AxiosError>(API.upgrades, fetcher);

  if (upgradesError) toast.error(upgradesError.message);

  const loading = !!upgradesError || (isLoadingNFTs && isLoadingUpgrades);
  const bonuses = getBonuses(upgrades);
  const stakedNFTs = collection?.map(Nft => ({
    id: Nft.index.toString(),
    description: `Diamore: Girls' Best Friends #${Nft.index}`,
    previewUrl: Nft.previews?.find(item => item.resolution === '500x500')?.url,
  }));

  const [showBonusDrawer, setBonusDrawerShowing] = useState<boolean>(false);
  const [showNftsDrawer, setNftsDrawerShowing] = useState<boolean>(false);

  const triggerShowBonusDrawer = () => setBonusDrawerShowing(!showBonusDrawer);
  const triggerShowNftsDrawer = () => setNftsDrawerShowing(!showNftsDrawer);

  const handleBuyNft = () => openLink('https://getgems.io/girlsbestfriends');

  if (loading) return <FullPageLoader />;

  return (
    <>
      {!loading && collection?.length ? (
        <>
          <StakingBlock
            bonuses={bonuses}
            NFTs={stakedNFTs}
            onBuyNft={handleBuyNft}
            onViewAllBonuses={triggerShowBonusDrawer}
            onViewAllNft={triggerShowNftsDrawer}
          />
          <BonusDrawer bonuses={bonuses} open={showBonusDrawer} setOpen={triggerShowBonusDrawer} />
          <NftDrawer NFTs={stakedNFTs} open={showNftsDrawer} setOpen={triggerShowNftsDrawer} />
        </>
      ) : (
        <BuyNftOnboarding onBuyNft={handleBuyNft} />
      )}
    </>
  );
};
