import React from 'react';
// components
import { Image } from '@chakra-ui/react';
//styles
import laurelBranchLeft from '../../../../assets/laurelBranchLeft.svg';
import laurelBranchRight from '../../../../assets/laurelBranchRight.svg';
import styles from './styles.module.scss';
// other
import { StakingBonus } from '../../../../types';

export interface IBonusCardProps {
  category: StakingBonus;
  value: string;
}

const BONUS_CATEGORY = {
  [StakingBonus.Daily]: 'DAILY',
  [StakingBonus.Games]: 'GAMES',
  [StakingBonus.Points]: 'POINTS',
  [StakingBonus.Experience]: 'EXPERIENCE',
  [StakingBonus.AIRdrop]: 'AIRDROP',
  [StakingBonus.RefFirstLVL]: 'FOR 1STLV ref.',
  [StakingBonus.RefSecondLVL]: 'FOR 2STLV ref.',
  [StakingBonus.RefThirdLVL]: 'FOR 3STLV ref.',
};

export const BonusCard: React.FC<IBonusCardProps> = ({ value, category }) => (
  <div className={styles.wrapper}>
    <Image src={laurelBranchLeft} alt='' />
    <Image src={laurelBranchRight} alt='' />
    <p className={styles.content}>
      <span>{value}</span>
      <span>{BONUS_CATEGORY[category]}</span>
    </p>
  </div>
);
