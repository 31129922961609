import React, { ReactNode, TouchEvent, MouseEventHandler } from 'react';
// components
import { Swiper, SwiperProps, SwiperSlide, SwiperSlideProps } from 'swiper/react';
//styles
import styles from './styles.module.scss';

interface ISlider<T> extends SwiperProps {
  title: string;
  children?: ReactNode;
  cardProps?: Array<T> & { id?: string };
  CardComponent?: React.FC<T>;
  slideProps?: SwiperSlideProps;
  onClickViewAll?: MouseEventHandler<HTMLButtonElement>;
}

export const Slider: React.FC<ISlider<any>> = ({
  children,
  title,
  slideProps,
  cardProps,
  CardComponent,
  onClickViewAll,
  ...props
}) => {
  const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div className={styles.wrapper} onTouchStart={handleTouchStart}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <button className={styles.viewAllBtn} onClick={onClickViewAll}>
          View all
        </button>
      </div>
      <Swiper
        className='w-full'
        slidesPerView='auto'
        spaceBetween={12}
        lazyPreloaderClass='lazy-preloader'
        preventClicksPropagation
        preventClicks
        cssMode
        {...props}
      >
        {children ||
          (CardComponent &&
            cardProps?.map((prop, index) => (
              <SwiperSlide key={index + prop?.id} {...slideProps} virtualIndex={index}>
                <CardComponent {...prop} />
              </SwiperSlide>
            )))}
      </Swiper>
    </div>
  );
};
