import React from 'react';
import { BingXAdvancedBanner, IAdvancedBannerConfig } from '../Banner/Advanced';
import { BingXHeader, IBingXHeaderProps } from './Header';
import { BingXTasks, IBingXTasks, IBingXTasksProps } from './TasksList';

type OmitedTasks = Omit<IBingXTasksProps, 'tasks'>;
type OmitedBanner = Omit<
  IAdvancedBannerConfig,
  'duration' | 'disabled' | 'showAlert' | 'small' | 'isNew' | 'onClick'
>;

interface IBingXFeedProps extends IBingXHeaderProps, OmitedTasks, OmitedBanner {
  tasks?: IBingXTasks;
}

export const BingXFeed: React.FC<IBingXFeedProps> = ({
  tasks,
  ballance,
  pinkBallance,
  availableReward,
  progress,
  ...tasksProps
}) => (
  <div className='container flexColumn'>
    <BingXHeader ballance={ballance} pinkBallance={pinkBallance} />
    <BingXAdvancedBanner availableReward={availableReward} progress={progress} small />
    {tasks && <BingXTasks tasks={tasks} {...tasksProps} />}
  </div>
);
