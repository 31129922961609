import React, { ReactNode } from 'react';
import { Image } from '@chakra-ui/react';
import styles from './styles.module.scss';

interface IDropLogoProps {
  logoSrc?: string;
  children?: ReactNode;
  classNames?: {
    drop?: string;
    logo?: string;
  };
}

export const DropLogo: React.FC<IDropLogoProps> = ({ children, logoSrc, classNames }) => (
  <div className={`${styles.dropLogo} ${classNames?.drop}`}>
    {children || <Image className={classNames?.logo} src={logoSrc} alt='banner logo' />}
  </div>
);
