import React from 'react';
import { ConnectedWallet } from '@tonconnect/ui-react';
// components
import { Layout } from '../../components/Layout';
import { AdaptiveHeightScroll } from '../../components/Scroll';
import { MintingOnboarding } from './components/Minting/Onboarding';
import { ConnectWallet } from './components/Staking/ConnectWallet';
import { Minting, Staking } from './containers';
// other
import { useConnectWallet } from '../../hooks';

export const Nft: React.FC = () => {
  const wallet = useConnectWallet();
  const isStakingStage = true;

  return (
    <Layout>
      {wallet ? (
        <>
          {isStakingStage ? (
            <Staking wallet={wallet as ConnectedWallet} />
          ) : (
            <Minting wallet={wallet as ConnectedWallet} />
          )}
        </>
      ) : (
        <AdaptiveHeightScroll>
          {isStakingStage ? <ConnectWallet /> : <MintingOnboarding />}
        </AdaptiveHeightScroll>
      )}
    </Layout>
  );
};
