import { IUpgrades } from '../../Home/types';
import { IBonusCardProps } from '../components/Staking/Card';
import { StakingBonus } from '../types';

type IBonusConfig = IBonusCardProps & { id: string };
type IUpgradeConfig = Omit<IUpgrades, 'tapCoolDown' | 'tapDuration' | 'tapPower'>;

const convertBonusValue = (category: string, value: string | number) => {
  switch (category) {
    case StakingBonus.Daily:
      return `+${Number(value).toFixed()} 💎`;
    case StakingBonus.Games:
      return `+${Number(value).toFixed()}`;
    case StakingBonus.Points:
      return `x${Number(value).toFixed(2)}`;
    case StakingBonus.RefFirstLVL:
      return `+${Number(value).toFixed(1)}%`;
    case StakingBonus.RefSecondLVL:
      return `+${Number(value).toFixed(1)}%`;
    case StakingBonus.RefThirdLVL:
      return `+${Number(value).toFixed(1)}%`;
    default:
      return '';
  }
};

const convertRefsBonusesByLevel = (refsBonusesByLevel: Array<number>, key?: string) =>
  refsBonusesByLevel.reduce<Array<IBonusConfig>>((refsByLevel, upgrade, idx) => {
    if (!!Number(upgrade)) {
      let category;

      switch (idx + 1) {
        case 1:
          category = StakingBonus.RefFirstLVL;
          break;
        case 2:
          category = StakingBonus.RefSecondLVL;
          break;
        case 3:
          category = StakingBonus.RefThirdLVL;
          break;
      }

      if (category) {
        refsByLevel.push({
          id: key ? `${idx}_${key}` : `${idx}_${category}`,
          value: convertBonusValue(category, upgrade * 100),
          category,
        });
      }
    }
    return refsByLevel;
  }, []);

export const getBonuses = (upgrades?: IUpgrades) => {
  if (!upgrades) return [];

  const bonusKeys: Array<keyof IUpgradeConfig> = [
    'dailyRewardsBonus',
    'additionalGameSessions',
    'tapsMultiplier',
    'additionalReferralPercentages',
  ];

  const categorysKeys = {
    dailyRewardsBonus: StakingBonus.Daily,
    additionalGameSessions: StakingBonus.Games,
    tapsMultiplier: StakingBonus.Points,
  };

  const bonuses = bonusKeys.reduce<Array<IBonusConfig>>((total, key, idx) => {
    if (key === 'additionalReferralPercentages') {
      const refsBonusesByLevel = convertRefsBonusesByLevel(upgrades[key] || [], key);

      total = [...total, ...refsBonusesByLevel];
    } else {
      const bonusValue = upgrades[key as keyof Omit<IUpgradeConfig, 'additionalReferralPercentages'>];

      if (!!Number(bonusValue)) {
        const category = categorysKeys[key];
        total.push({
          id: `${idx}_${key}`,
          value: convertBonusValue(category, bonusValue),
          category,
        });
      }
    }

    return total;
  }, []);

  return bonuses;
};
