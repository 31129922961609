import React from 'react';
import { KeyedMutator } from 'swr';
// components
import { Box, Flex, Text } from '@chakra-ui/react';
import { List } from '../../../../../../components/List';
import { TaskCard } from '../../TaskCard';
// styles
import styles from './styles.module.scss';
// other
import { IQuest } from '../../../../types';

export interface IBingXTasks {
  available: Array<IQuest>;
  completed?: Array<IQuest>;
}

export interface IBingXTasksProps {
  tasks: IBingXTasks;
  mutate?: KeyedMutator<any>;
}

export const BingXTasks: React.FC<IBingXTasksProps> = ({ tasks: { available, completed = [] }, mutate }) => (
  <Box display='flex' flexDirection='column' flex='1'>
    <List>
      {available.length > 0 && (
        <Flex flexDirection='column'>
          <Box className={styles.title} width='100%' alignSelf='center'>
            <Text>AVAILABLE</Text>
          </Box>
          {available.map(item => (
            <TaskCard key={item._id} quest={item} mutateQuests={mutate} />
          ))}
        </Flex>
      )}
      {completed.length > 0 && (
        <Flex flexDirection='column' marginTop='32px' transition='all 0.3s ease'>
          <Box className={styles.title} width='100%' alignSelf='center'>
            <Text>FINISHED</Text>
          </Box>
          {completed.map(item => (
            <TaskCard key={item._id} quest={item} mutateQuests={mutate} />
          ))}
        </Flex>
      )}
    </List>
  </Box>
);
