import React from 'react';
// components
import { Tag as ChakraTag, TagLabel, TagProps } from '@chakra-ui/react';
// styles
import styles from './styles.module.scss';

export interface ITagProps extends TagProps {
  text: string;
  icon?: string | JSX.Element;
}

export const Tag: React.FC<ITagProps> = ({ text, icon, children, className, ...tagProps }) => (
  <ChakraTag className={`${className} ${styles.wrapper}`} variant='subtle' {...tagProps}>
    {children || (
      <>
        {icon}
        <TagLabel>{text}</TagLabel>
      </>
    )}
  </ChakraTag>
);

export default Tag;
