import React, { CSSProperties, ReactNode } from 'react';
import styles from './styles.module.scss';

export interface IAdaptiveHeightScrollProps {
  useBorderBottom?: boolean;
  contentStyles?: CSSProperties;
  children: ReactNode;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
}

export const AdaptiveHeightScroll: React.FC<IAdaptiveHeightScrollProps> = ({
  children,
  useBorderBottom,
  contentStyles,
  onScroll,
}) => (
  <div className={styles.fixedHeight}>
    <div
      style={contentStyles}
      className={useBorderBottom ? styles.contentWithBorderBottom : styles.content}
      onScroll={e => onScroll?.(e)}
    >
      {children}
    </div>
  </div>
);
