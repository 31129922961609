import { useEffect, useState } from 'react';
import { NftItem } from 'tonapi-sdk-js';
import { NftAPI } from '../../../api/NFT';
import { useInterval } from '../../../hooks/useInterval';
import { useDarkToast } from '../../../hooks/useToast';
import { Network, StorageKey } from '../../../types';

interface INFTs {
  isNFTExist: boolean;
  collection: NftItem[];
}

interface TonNFTApiConfig {
  showLoader?: boolean;
  checkNftOnFirstLoadin?: boolean;
  onlyFirstLoading?: boolean;
}

export const useTonNFTApi = (
  NFTAddress: string,
  ownerAddress: string,
  network: Network,
  config: TonNFTApiConfig = {
    showLoader: false,
    checkNftOnFirstLoadin: false,
    onlyFirstLoading: false,
  }
): {
  NFTs: INFTs;
  loading: boolean;
  mutate: (values: Partial<INFTs>) => void;
  checkForNFT: () => Promise<boolean>;
  checkMintingTrigger: () => void;
} => {
  const { showToast } = useDarkToast();

  const API = new NftAPI(network);
  const { showLoader = false, onlyFirstLoading = false, checkNftOnFirstLoadin = false } = config;

  const savedMinted: string | null = localStorage.getItem(StorageKey.Minted);
  const minted: boolean = !!savedMinted && JSON.parse(savedMinted);

  const savedCheckingMint: string | null = localStorage.getItem(StorageKey.CheckingMint);
  const checkingMint: boolean = !!savedCheckingMint && JSON.parse(savedCheckingMint);

  const [NFTs, setNFTs] = useState<INFTs>({ isNFTExist: minted, collection: [] });
  const [checkMinting, setMintingCheck] = useState<boolean>(checkingMint);
  const [loading, setLoading] = useState<boolean>(showLoader);

  const checkMintingTrigger = (): void => {
    setMintingCheck(!checkMinting);
    localStorage.setItem(StorageKey.CheckingMint, JSON.stringify(!checkMinting));
  };

  const mutate = (values: Partial<INFTs>): void => {
    const { isNFTExist } = values;
    minted !== isNFTExist && localStorage.setItem(StorageKey.Minted, JSON.stringify(isNFTExist));
    setNFTs({ ...NFTs, ...values });
  };

  const checkForNFT = async (): Promise<boolean> => {
    showLoader && !onlyFirstLoading && setLoading(true);
    let isNFTExist = false;

    try {
      const collection = await API.getNftByOwner(ownerAddress, NFTAddress);
      isNFTExist = !!collection?.length;

      NFTs.collection?.length !== collection?.length && mutate({ isNFTExist, collection });
    } catch (e) {
      console.error(e);
    }

    showLoader && !onlyFirstLoading && setLoading(false);

    return isNFTExist;
  };

  const updateNftAvailability = async (): Promise<void> => {
    if (!ownerAddress && !NFTAddress) return;

    const isNFTExist: boolean = await checkForNFT();

    if (isNFTExist) {
      setMintingCheck(!isNFTExist);
      localStorage.removeItem(StorageKey.CheckingMint);
      showToast('NFT HAS BEEN SUCCESSFULLY ADDED');
    } else {
      NFTs.isNFTExist !== isNFTExist && mutate({ isNFTExist });
    }
  };

  useInterval(updateNftAvailability, { ms: 10000, active: checkMinting });

  useEffect(() => {
    const checkNFTCollection = async () => {
      showLoader && onlyFirstLoading && setLoading(true);
      await checkForNFT();
      showLoader && onlyFirstLoading && setLoading(false);
    };

    checkNftOnFirstLoadin && checkNFTCollection();
  }, [checkNftOnFirstLoadin]); // eslint-disable-line react-hooks/exhaustive-deps

  return { NFTs, loading, mutate, checkForNFT, checkMintingTrigger };
};
