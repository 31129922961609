import React, { MouseEventHandler } from 'react';
// componens
import { Button } from '../../../../components/Button';
import { CustomTonWalletButton } from '../../../../components/TonWalletButton';
import { AdaptiveHeightScroll } from '../../../../components/Scroll';
import { OnClickaBanner } from '../../../Home/components/Quests/Banner';
import { BonusCard, IBonusCardProps, INftCardProps, NftCard } from './Card';
import { Slider } from './Slider';
// styles
import styles from './styles.module.scss';

interface IStakingBlockProps {
  bonuses: Array<IBonusCardProps>;
  NFTs: Array<INftCardProps>;
  onBuyNft?: MouseEventHandler<HTMLButtonElement>;
  onViewAllNft?: MouseEventHandler<HTMLButtonElement>;
  onViewAllBonuses?: MouseEventHandler<HTMLButtonElement>;
}

export const StakingBlock: React.FC<IStakingBlockProps> = ({
  bonuses,
  NFTs,
  onBuyNft,
  onViewAllNft,
  onViewAllBonuses,
}) => (
  <div className={styles.wrapper}>
    <header className={styles.header}>
      <CustomTonWalletButton />
      <Button onClick={onBuyNft}>BUY NFT</Button>
    </header>
    <AdaptiveHeightScroll>
      <div className={styles.content}>
        <div className={styles.column}>
          <Slider
            title='Staking Bonuses'
            cardProps={bonuses}
            CardComponent={BonusCard}
            slideProps={{ style: { width: '170px' } }}
            onClickViewAll={onViewAllBonuses}
          />
          <Slider
            title='Staked'
            cardProps={NFTs}
            CardComponent={NftCard}
            lazyPreloadPrevNext={5}
            slideProps={{ style: { width: '180px' }, lazy: true }}
            onClickViewAll={onViewAllNft}
          />
        </div>
        <OnClickaBanner />
      </div>
    </AdaptiveHeightScroll>
  </div>
);
