import React from 'react';
// components
import { List } from '../../../../../../components/List';
import { Drawer, IDrawerProps } from '../../../../../../components/Modal';
//styles
import styles from './styles.module.scss';
// other
import { IBonusCardProps } from '../../Card';
import { StakingBonus } from '../../../../types';

export interface IBonusDrawerProps extends Omit<IDrawerProps, 'children'> {
  bonuses: Array<IBonusCardProps & { id: string }>;
}

const BONUS_CATEGORY = {
  [StakingBonus.Daily]: 'Daily reward',
  [StakingBonus.Games]: 'Daily Game',
  [StakingBonus.Points]: 'Points multibonus',
  [StakingBonus.Experience]: 'Experience bonus',
  [StakingBonus.AIRdrop]: 'Access to AIRdrop',
  [StakingBonus.RefFirstLVL]: 'Bonus 1stLV ref.',
  [StakingBonus.RefSecondLVL]: 'Bonus 2stLV ref.',
  [StakingBonus.RefThirdLVL]: 'Bonus 3stLV ref.',
};
export const BonusDrawer: React.FC<IBonusDrawerProps> = ({ bonuses, ...drawerProps }) => (
  <Drawer {...drawerProps}>
    <h3 className={styles.title}>Total Bonuses</h3>
    <List>
      {bonuses.map(({ id, value, category }, idx) => (
        <div key={idx + id} className={styles.card}>
          <p className={styles.cardContent}>
            <span>{BONUS_CATEGORY[category]}</span>
            <span>{value}</span>
          </p>
        </div>
      ))}
    </List>
  </Drawer>
);
