import { IUser, StorageKey } from '../types';

export const checkAndUpdateUser = (user?: IUser) => {
  const userIdFromTelegram = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id.toString();
  const savedUserId = localStorage.getItem(StorageKey.TelegramUserId);
  const existingUserId = userIdFromTelegram || user?.id;

  if (savedUserId && savedUserId !== userIdFromTelegram) {
    localStorage.removeItem('ton-connect-storage_bridge-connection');
    localStorage.removeItem('ton-connect-ui_wallet-info');
    localStorage.removeItem('wallet');
  }

  // Adding user telegram context for development locally.
  if (!userIdFromTelegram && user) {
    window.Telegram.WebApp.initDataUnsafe.user = {
      id: Number(user?.id),
      first_name: user?.first_name,
      language_code: 'en',
    };
  }

  existingUserId && localStorage.setItem(StorageKey.TelegramUserId, existingUserId);
};
