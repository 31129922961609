import React, { MouseEvent, MouseEventHandler, useRef, useState } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
// components
import { Image } from '@chakra-ui/react';
//styles
import styles from './styles.module.scss';
import exitIcon from '../../../assets/images/exit.svg';
//other
import { obfuscateString } from '../../../utils/obfuscateString';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { useTonAddress } from '../../../hooks/useTonAddress';

export interface ICustomTonWalletButtonProps {
  className?: string;
  onClick?: MouseEventHandler;
}

export const CustomTonWalletButton: React.FC<ICustomTonWalletButtonProps> = ({ className, onClick }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [tonConnectUI] = useTonConnectUI();
  const walletAddress = useTonAddress();

  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    setIsPopupVisible(!isPopupVisible);
    onClick?.(e);
  };

  const handleClosePopup = () => setIsPopupVisible(false);

  const handleDisconnect = async (e: MouseEvent<HTMLButtonElement>) => {
    await tonConnectUI.disconnect();
    handleClosePopup();
    onClick?.(e);
  };

  useOnClickOutside(containerRef, handleClosePopup);

  if (!walletAddress) return <></>;

  return (
    <div className={styles.container} ref={containerRef}>
      <button className={`${styles.wrapper} ${className}`} onClick={handleButtonClick}>
        💳 {obfuscateString(walletAddress, 5, 4, '.', 4)}
        <span className={styles.arrow} />
      </button>
      <div className={`${styles.popup} ${isPopupVisible ? styles.active : ''}`}>
        <button className={styles.popupButton} onClick={handleDisconnect}>
          <span className={styles.icon}>
            <Image src={exitIcon} alt='icon' />
          </span>
          Disconnect
        </button>
      </div>
    </div>
  );
};

export default CustomTonWalletButton;
