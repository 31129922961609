import { useState } from 'react';

export const useLocalStorage = <T>(
  key: string,
  initialValue?: T
): [storedValue: T, setValue: (value: T) => void, removeValue: () => void] => {
  const canUseLocalStorage = typeof window !== 'undefined';

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = canUseLocalStorage && localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T): void => {
    canUseLocalStorage && localStorage.setItem(key, JSON.stringify(value));
    setStoredValue(value);
  };

  const removeValue = (): void => {
    canUseLocalStorage && localStorage.removeItem(key);
    setStoredValue(undefined);
  };

  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
