import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ConnectedWallet, useTonConnectUI, useTonWallet, Wallet } from '@tonconnect/ui-react';
import { AxiosError } from 'axios';
import { API, loggedAxios } from '../api';
import { useLocalStorage } from './useLocalStorage';
import { StorageKey } from '../types';

interface IConnectWalletConfig {
  checkIsWalletExist?: boolean;
  onStartConnect?: () => void;
  onConnectWallet?: (wallet: Wallet | ConnectedWallet | null) => void | undefined;
  onChangeWallet?: (wallet: Wallet | ConnectedWallet | null) => void | undefined;
  onCheckWallet?: (wallet: Wallet | ConnectedWallet | null) => void | undefined;
  finallyConnect?: (wallet: Wallet | ConnectedWallet | null) => void | undefined;
}

export const useConnectWallet = ({
  checkIsWalletExist = true,
  onStartConnect,
  onConnectWallet,
  onChangeWallet,
  onCheckWallet,
  finallyConnect,
}: IConnectWalletConfig = {}): Wallet | ConnectedWallet | null => {
  const tonWallet = useTonWallet();
  const [storedWallet, setStoredWallet, removeStoredWallet] = useLocalStorage<Wallet | ConnectedWallet>(
    StorageKey.Wallet
  );

  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    const handleAccountChange = (wallet: ConnectedWallet | null) => {
      if (!wallet && storedWallet) {
        removeStoredWallet();
      } else if (wallet) {
        const isDifferentWallets = storedWallet?.account?.address !== wallet?.account?.address;
        isDifferentWallets && setStoredWallet(wallet);
      }

      onChangeWallet?.(wallet);
    };

    tonConnectUI.onStatusChange(handleAccountChange);

    return () => {
      tonConnectUI.onStatusChange(handleAccountChange);
    };
  }, [tonConnectUI, storedWallet, setStoredWallet, removeStoredWallet, onChangeWallet]);

  useEffect(() => {
    onStartConnect?.();

    const sendWallet = async (wallet: ConnectedWallet) => {
      try {
        await loggedAxios.post(API.walletConnect, {
          address: wallet?.account?.address,
          name: wallet?.appName,
        });
        onConnectWallet?.(wallet);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error?.response?.status === 409) {
            tonConnectUI.connected && tonConnectUI.disconnect();
            toast(error?.response?.data?.message);
          }
        }
      }
    };

    if (checkIsWalletExist && tonWallet) {
      onCheckWallet?.(tonWallet);
      sendWallet(tonWallet as ConnectedWallet);
    } else if (checkIsWalletExist && !tonWallet) {
      onCheckWallet?.(tonWallet);
    }

    finallyConnect?.(tonWallet);
  }, [
    tonWallet,
    tonConnectUI,
    checkIsWalletExist,
    onStartConnect,
    onConnectWallet,
    onCheckWallet,
    finallyConnect,
  ]);

  return tonWallet;
};
