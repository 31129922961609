import React from 'react';
// components
import { Image, Tag, TagLabel } from '@chakra-ui/react';
// styles
import styles from './styles.module.scss';

interface IFriendsCardTagProps {
  text: string;
  iconSrc?: string;
}

export const FriendsCardTag: React.FC<IFriendsCardTagProps> = ({ text, iconSrc }) => (
  <Tag className={styles.wrapper} variant='subtle'>
    {iconSrc && <Image className='w-[18px]' src={iconSrc} alt='icon' />}
    <TagLabel>{text}</TagLabel>
  </Tag>
);
