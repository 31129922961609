import React from 'react';
import { Progress, ProgressProps } from '@chakra-ui/react';
import styles from './styles.module.scss';

export interface IProgress {
  total: number;
  completed: number;
}

export interface IProgressBarProps extends ProgressProps {
  label?: string;
  progress: IProgress;
  showPercent?: boolean;
  classNames?: {
    wrapper?: string;
    header?: string;
    progress?: string;
  };
}

export const ProgressBar: React.FC<IProgressBarProps> = ({
  label = 'Total',
  progress,
  showPercent,
  classNames,
  ...props
}) => {
  const { total = 0, completed = 0 } = progress;

  const percent = +Number((completed / total) * 100 || 0).toFixed();
  const shortProgress = `${completed}/${total}`;

  return (
    <div className={classNames?.wrapper || styles.wrapper}>
      <div className={classNames?.header || styles.header}>
        <h5>{label}</h5>
        <p>{showPercent ? `${percent}% (${shortProgress})` : shortProgress}</p>
      </div>
      <Progress className={classNames?.progress || styles.progress} value={percent} {...props} />
    </div>
  );
};
