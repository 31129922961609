import React from 'react';
// components
import { Button, Spinner, Image } from '@chakra-ui/react';
import { Icon } from '../../../components/Icon';
// styles
import diamondSvg from '../../../assets/images/diamond.svg';
import img from '../assets/mini.png';
import styles from './styles.module.scss';
// other
import { formatPrice } from '../../../utils/formattingHelpers';
import { hapticImpact } from '../../../utils/hapticFeedback';
import { IDiamondCard } from '../types/interfaces/IDiamondItem';

const renderIcon = () => <Image className='w-[16px] inline-block' src={diamondSvg} alt='diamond' />;

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const transformKey = (key: string) => {
  const upperCaseKeys = ['apy'];

  if (upperCaseKeys.includes(key.toLowerCase())) return key.toUpperCase();

  return capitalizeFirstLetter(key);
};

const formatValue = (key: string, value: any) => {
  if (key.toLowerCase() === 'apy') return (value * 100).toFixed(2) + ' %';
  if (key.toLowerCase() === 'price')
    return (
      <span className='!flex items-center gap-1'>
        {value} {renderIcon()}
      </span>
    );
  return value;
};

interface IDiamondCardProps {
  item: IDiamondCard;
  onClick?: () => void;
  linkLabel: string;
  hiddenKeys?: string[];
  time?: number;
  period?: string;
  disabled?: boolean;
  isLoading?: boolean;
  claimBonus?: number;
  isStaked?: boolean;
  link?: string;
}

export const DiamondCard: React.FC<IDiamondCardProps> = ({
  item,
  onClick,
  linkLabel,
  hiddenKeys = ['id', 'createdAt', 'name', 'price', 'type'],
  time,
  period,
  disabled,
  isLoading = false,
  claimBonus,
  isStaked,
}) => {
  const keysToDisplay = Object.keys(item).filter(key => !hiddenKeys.includes(key));

  return (
    <div className={styles.wrapper}>
      <img src={img} className={styles.img} loading={'lazy'} alt='' />
      <div>
        <div className={styles.price}>
          {' '}
          {isStaked ? claimBonus?.toFixed(4) : formatPrice(item.price)} <Icon sizeInPx={24} />
        </div>
        <div className={styles.name}>{item.type === 'Demo' ? '[Demo] ' + item.name : item.name}</div>
        <div className={styles.properties}>
          {keysToDisplay.map(key => (
            <p key={key}>
              <span>{transformKey(key)}</span>
              {formatValue(key, item[key])}
            </p>
          ))}
          {isStaked && (
            <p>
              <span>Time left</span>
              {time} days
            </p>
          )}
          {period && (
            <p>
              <span>Period</span>
              {period}
            </p>
          )}
        </div>
      </div>
      <Button
        display='flex'
        alignItems='center'
        gap='4px'
        onClick={onClick}
        variant={'primary'}
        className={`${styles.btn} ${disabled ? styles.disabled : ''}`}
        onTouchStart={() => hapticImpact('medium')}
      >
        {linkLabel}
        {isLoading && (
          <Spinner thickness='1px' speed='0.65s' emptyColor='gray.200' color='gray.900' size='xs' />
        )}
      </Button>
    </div>
  );
};
