import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { KeyedMutator } from 'swr';
//components
import { CustomPopup } from '../../../components/Modal';
//other
import { IUser, StorageKey } from '../../../types';
import { API, loggedAxios } from '../../../api';

import img from '../../../assets/images/diamondImage.png';

const rewardPopup = {
  title: 'Active user bonus',
  desc: 'Happy to have you onboard! Claim a reward for being an active user!',
  btnLabel: 'Claim',
};

interface IPopupProps {
  mutate: KeyedMutator<IUser>;
}

const REWARD_POPUP_SHOW_LIMIT = 3;

export const Popup: React.FC<IPopupProps> = ({ mutate }) => {
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const hasPopupBeenShown = localStorage.getItem(StorageKey.RewardPopupShowLimit);
    if (!hasPopupBeenShown || Number(hasPopupBeenShown) < REWARD_POPUP_SHOW_LIMIT) {
      onOpen();
    }
  }, [onOpen]);

  const closeModal = async () => {
    const promocode1 = 'undX6YMK3jP4s9vaDHbqgEtVcL2eypm8xAzZhU-blue';
    const promocode2 = 'undX6YMK3jP4s9vaDHbqgEtVcL2eypm8xAzZhU-pink';

    try {
      setIsClaimLoading(true);

      await Promise.all([
        loggedAxios.post(API.sendPromocode, { code: promocode1 }),
        loggedAxios.post(API.sendPromocode, { code: promocode2 }),
      ]);

      localStorage.setItem(StorageKey.RewardPopupShowLimit, String(REWARD_POPUP_SHOW_LIMIT)); //popup will not show again
      mutate();
    } catch (e: any) {
      console.error(e?.response.data?.message);
      toast('Something went wrong!');

      const hasPopupBeenShown = localStorage.getItem(StorageKey.RewardPopupShowLimit);
      localStorage.setItem(StorageKey.RewardPopupShowLimit, String(Number(hasPopupBeenShown) + 1));
    } finally {
      setIsClaimLoading(false);
      onClose();
    }
  };

  return (
    <CustomPopup
      isOpen={isOpen}
      closeModal={closeModal}
      title={rewardPopup.title}
      description={rewardPopup.desc}
      btnLabel={rewardPopup.btnLabel}
      isLoading={isClaimLoading}
      img={img}
    />
  );
};
