import React, { useRef, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { toast } from 'react-toastify';
import { KeyedMutator } from 'swr';
// components
import { ClickerFloaters } from '../components/Game/Floaters/ClickerFloaters';
import {
  ClickerViewProvider,
  GAME_STATE_VARIANTS,
  tapPauseInterval,
} from '../components/Game/ClickerViewProvider';
import { Playground } from '../components/Game/Playground/Playground';
// other
import { useClickAnimation } from '../hooks/useClickAnimation';
import { usePreventSwipeClose } from '../../../hooks/usePreventSwipeClose';
import { hapticImpact } from '../../../utils/hapticFeedback';
import { TimePassedFromGameStart } from '../utils';
import { IUser } from '../../../types';
import { IUpgrades } from '../types';

let isTouch = false;

interface IClickerProps {
  userInfo: IUser;
  upgrades: IUpgrades;
  mutate: KeyedMutator<IUser>;
}

export const Clicker: React.FC<IClickerProps> = ({ userInfo, upgrades, mutate }) => {
  const weeklyBoostPoints = Number(upgrades?.tapPower[0]?.value || 1) * Number(upgrades?.tapsMultiplier || 1);
  const tapDurationMil = upgrades?.tapDuration[0]?.durationMil || 10000;

  const [tapBonusesCurrentSession, setTapBonusesCurrentSession] = useState(0);
  const timePassed = TimePassedFromGameStart(userInfo);
  const defaultView =
    timePassed > tapPauseInterval ? GAME_STATE_VARIANTS.readyToPlay : GAME_STATE_VARIANTS.afterGame;
  const diamondRef = useRef(null);
  usePreventSwipeClose(diamondRef);
  const currentTimerValue = useRef(0);
  const { clickFloaters, onTouchHandler } = useClickAnimation();
  const [gameViewState, setGameViewState] = useState(defaultView);
  const loadingRef = useRef(false);

  const modifiedTouch = (event: TouchEvent) => {
    isTouch = true;
    if (loadingRef.current) {
      return;
    }
    if (gameViewState === GAME_STATE_VARIANTS.inGame) {
      hapticImpact('medium');
      setTapBonusesCurrentSession(p => {
        const currentTime = (tapDurationMil - currentTimerValue.current) / 1000;
        if (currentTime < p / (50 * weeklyBoostPoints)) {
          return p;
        }
        onTouchHandler(event);

        return Number((p + weeklyBoostPoints).toFixed(1));
      });
    }
    if (gameViewState === GAME_STATE_VARIANTS.readyToPlay) {
      setTapBonusesCurrentSession(0);
      setGameViewState(GAME_STATE_VARIANTS.inGame);
    }
  };

  const onDesktopClick = () => {
    if (isAndroid || isIOS) {
      return;
    }
    if (!isTouch) {
      toast.error('sorry it works only on a mobile phone');
    }
  };

  return (
    <Playground
      gameViewState={gameViewState}
      onClick={onDesktopClick}
      onTouchEnd={e => modifiedTouch(e as never)}
    >
      <ClickerViewProvider
        tapBonusesCurrentSession={tapBonusesCurrentSession}
        tapDurationMil={tapDurationMil}
        setTapBonusesCurrentSession={setTapBonusesCurrentSession}
        gameViewState={gameViewState}
        setGameViewState={setGameViewState}
        mutate={mutate}
        loadingRef={loadingRef}
        currentTimerValue={currentTimerValue}
      />
      <ClickerFloaters clickFloaters={clickFloaters} />
    </Playground>
  );
};

export default Clicker;
