import React from 'react';
import useSWR from 'swr';
// components
import { Layout } from '../../../components/Layout';
import { BingXFeed } from '../components/Quests/BingX';
// other
import { API, fetcher } from '../../../api';
import { IUser } from '../../../types';
import { Balance, IQuest, Status } from '../types';
import { IAvailableReward } from '../components/Quests';

export const BingX: React.FC = () => {
  const { data: user, isLoading: isUserLoading, mutate: mutateUser } = useSWR<IUser>(API.userInfo, fetcher);
  const {
    data: bingX,
    isLoading: isBingXLoading,
    mutate: mutateQuests,
  } = useSWR<{ quests: Array<IQuest> }>(API.bingX, fetcher);

  const handleMuteteData = () => {
    mutateUser();
    return mutateQuests();
  };

  const availableQuests = bingX?.quests?.filter(quest => quest?.status !== Status.Completed) || [];
  const completedQuests = bingX?.quests?.filter(quest => quest?.status === Status.Completed) || [];

  const tasks = {
    available: availableQuests,
    completed: completedQuests,
  };

  const progress = {
    total: bingX?.quests?.length || 0,
    completed: completedQuests?.length,
  };

  const availableReward = availableQuests?.reduce<Required<Omit<IAvailableReward, 'text'>>>(
    (totalRewards, { targetBalance, bonusClicks }) => {
      if (bonusClicks) {
        switch (targetBalance) {
          case Balance.Main:
            totalRewards.main = totalRewards.main + bonusClicks;
            break;
          case Balance.Pink:
            totalRewards.pink = totalRewards.pink + bonusClicks;
            break;
          default:
            break;
        }
      }

      return totalRewards;
    },
    { main: 0, pink: 0 }
  );

  const loading = isUserLoading && isBingXLoading;

  return (
    <Layout loading={loading}>
      {!loading && user && (
        <BingXFeed
          ballance={user?.balance}
          pinkBallance={user?.pinkBalance}
          tasks={tasks}
          availableReward={availableReward}
          progress={progress}
          mutate={handleMuteteData}
        />
      )}
    </Layout>
  );
};
