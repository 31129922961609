import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
// components
import { Banner, IBannerProps } from '../Banner';
import { BingXLogo } from '../Logo/BingX';
// styles
import bgWithRaysPng from '../../../../assets/bannerBgWithRays.png';
import styles from './styles.module.scss';
// other
import { API, fetcher } from '../../../../../../api';
import { ROUTES } from '../../../../../../constants';

interface IBingXBannerProps extends Omit<IBannerProps, 'title' | 'description' | 'image'> {
  description?: string | JSX.Element;
}

export const BingXBanner: React.FC<IBingXBannerProps> = bannerProps => {
  const navigate = useNavigate();

  const { data: bingX, isLoading } = useSWR<{ enabled: boolean }>(API.bingX, fetcher);

  const onOpen = () => navigate(`${ROUTES.bingX}`);

  return (
    <>
      {!isLoading && bingX?.enabled && (
        <Banner
          title='BingX'
          description='Pink hearts waiting for you'
          image={<BingXLogo />}
          bgImgSrc={bgWithRaysPng}
          tag={{ icon: '💫', text: 'New' }}
          classNames={styles}
          onClick={onOpen}
          {...bannerProps}
        />
      )}
    </>
  );
};
