import React, { useState } from 'react';
import { TadsWidget } from 'react-tads-widget';
// components
import { List } from '../../../../../components/List';
import { Button } from '../../../../../components/Button';
import { GifTextBlock } from '../../../../../components/GifTextBlock';
import { FullPageLoader } from '../../../../../components/FullPageLoader';
// styles
import rocketGif from '../../../../../assets/images/gif/rocket.gif';
import styles from './styles.module.scss';

interface ITadsFeedsProps {
  widgetId: string;
  onShowReward?: (adId: string) => void;
  onClickReward?: (adId: string) => void;
  onAdsNotFound?: () => void;
  onClickLuckyBtn?: () => void;
  onClickBack?: () => void;
}

export const TadsFeeds: React.FC<ITadsFeedsProps> = ({
  widgetId,
  onShowReward,
  onClickReward,
  onAdsNotFound,
  onClickLuckyBtn,
  onClickBack,
}) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isFeedsExist, setFeedsExist] = useState<boolean>(true);

  const handleShowReward = (adId: string) => {
    onShowReward?.(adId);
    isLoading && setLoading(false);
  };

  const handleAdsNotFound = () => {
    onAdsNotFound?.();
    isLoading && setLoading(false);
    setFeedsExist(false);
  };

  return (
    <>
      {isFeedsExist ? (
        <div className={styles.wrapper}>
          <List title='Proposals'>
            <TadsWidget
              id={widgetId}
              onShowReward={handleShowReward}
              onClickReward={onClickReward}
              onAdsNotFound={handleAdsNotFound}
            />
          </List>
          {onClickLuckyBtn && (
            <Button fullWidth onClick={onClickLuckyBtn}>
              I’ll be lucky
            </Button>
          )}
          {isLoading && <FullPageLoader />}
        </div>
      ) : (
        <>
          <GifTextBlock title='There’s no tasks here yet' subTitle='Come back later' img={rocketGif} />
          <Button fullWidth onClick={onClickBack}>
            Check other quests
          </Button>
        </>
      )}
    </>
  );
};
