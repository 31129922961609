import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConnectedWallet, Wallet } from '@tonconnect/ui-react';
import { mutate } from 'swr/_internal';
import { AxiosError } from 'axios';
// components
import { Flex } from '@chakra-ui/react';
import { Button } from '../../../../../../components/Button';
import { TonWalletButton } from '../../../../../../components/TonWalletButton';
// styles
import arrowUpRightSVG from '../../../../assets/arrowUpRight.svg';
// other
import { API, loggedAxios, sendTgAnalyticsEvent } from '../../../../../../api';
import { useConnectWallet, useLocalStorage, useOpen } from '../../../../../../hooks';
import { hapticImpact } from '../../../../../../utils/hapticFeedback';
import { isTelegramLink, openLink, openTelegramLink } from '../../../../../../utils/openTelegramLink';
import { ROUTES } from '../../../../../../constants';
import { StorageKey } from '../../../../../../types';
import { IQuest, Quest, Status } from '../../../../types';

interface IBottomNavBarProps {
  quest: IQuest;
  mutateQuest: () => Promise<void>;
}

export const BottomNavBar: React.FC<IBottomNavBarProps> = ({ quest, mutateQuest }) => {
  const userId = window?.localStorage.getItem(StorageKey.TelegramUserId);

  const navigate = useNavigate();

  const { _id: questId, name, url, status, bonusClicks, checkType } = quest || {};

  const [storedStatus, setStoredStatus] = useLocalStorage(questId);

  const STATUS = {
    COMPLETED: status === Status.Completed,
    CHECKING: status === Status.Checking,
  };

  const TYPE = {
    CONNECT_WALLET: checkType === Quest.WalletConnection,
    QUIZE_TEST: checkType === Quest.Quiz,
  };

  const { isOpen: canStartQuest, onOpen: onStartQuest, onClose: onFinishedQuest } = useOpen(true);
  const appName = String(process.env.REACT_APP_NAME);

  const handleQuestCompletion = async () => {
    const sessionId = window?.sessionStorage.getItem(StorageKey.SessionId);
    await mutateQuest();
    onStartQuest();
    hapticImpact('medium');
    try {
      await loggedAxios.post(API.finishQuest, { questName: name });

      if (sessionId) {
        await sendTgAnalyticsEvent('custom-event', Number(userId), sessionId, appName, {
          client_timestamp: new Date().getTime().toString(),
          custom_data: {
            questId,
            event_name: 'quest-completed',
          },
        }).then();
      }
    } catch (error) {
      console.warn(error);
      if (error instanceof AxiosError) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      await mutateQuest();
      await mutate(API.userInfo);
      onFinishedQuest();
    }
  };

  const onOpenLink = async () => {
    const isTelegramUrl = isTelegramLink(url);
    hapticImpact('medium');
    setStoredStatus(Status.Checking);
    onFinishedQuest();
    try {
      isTelegramUrl ? openTelegramLink(url) : openLink(url);
    } catch (e) {
      console.warn(e);
    }
  };

  const startTest = () =>
    navigate(`${ROUTES.quiz.replace(':id', questId)}?bonus=${bonusClicks}&status=${status}`);

  const startConnectWallet = () => {
    if (storedStatus === Status.Checking) {
      onFinishedQuest();
    }
  };

  const onCheckWallet = (wallet: Wallet | ConnectedWallet | null) => {
    if (TYPE.CONNECT_WALLET && wallet) {
      setStoredStatus(Status.Checking);
      onFinishedQuest();
    }
  };

  useConnectWallet({
    checkIsWalletExist: TYPE.CONNECT_WALLET,
    onStartConnect: startConnectWallet,
    onCheckWallet,
  });

  return (
    <Flex width='100%' padding='0 16px' direction='column' gap='8px'>
      {TYPE.QUIZE_TEST ? (
        <>
          <Button fullWidth onClick={onOpenLink}>
            DISCOVER
            <img width='16px' height='10px' src={arrowUpRightSVG} alt='arrowUpRightSVG' />
          </Button>
          <Button secondary fullWidth onClick={startTest}>
            START TEST
          </Button>
        </>
      ) : (
        <>
          {TYPE.CONNECT_WALLET ? (
            <TonWalletButton onClick={onOpenLink} />
          ) : (
            <Button fullWidth onClick={onOpenLink}>
              Let’s start
            </Button>
          )}
          <Button
            secondary
            fullWidth
            isDisabled={canStartQuest || STATUS.CHECKING || STATUS.COMPLETED}
            onClick={handleQuestCompletion}
          >
            {STATUS.COMPLETED ? 'Finished' : STATUS.CHECKING ? 'Checking' : 'Check the execution'}
          </Button>
        </>
      )}
    </Flex>
  );
};
