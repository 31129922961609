import { Image } from '@chakra-ui/react';
import React from 'react';
//styles
import styles from './styles.module.scss';
//other
import diamondSvg from '../../assets/images/diamond.svg';
import pinkHeartSvg from '../../assets/images/pinkHeart.svg';

const ICONS = {
  diamond: diamondSvg,
  heart: pinkHeartSvg,
};
type TIcons = typeof ICONS;

interface IconProps {
  icon?: 'diamond' | 'heart';
  alt?: 'diamondImg' | 'heartImg';
  classname?: string;
  sizeInPx?: number;
}

export const Icon: React.FC<IconProps> = ({
  icon = 'diamond',
  alt = 'diamondImg',
  classname,
  sizeInPx = 20,
}) => (
  <span className={`${styles.wrapper} ${classname}`} style={{ width: sizeInPx }}>
    <Image src={ICONS[icon as keyof TIcons]} alt={alt} />
  </span>
);
