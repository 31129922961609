export enum StakingBonus {
  Daily = 'daily',
  Games = 'games',
  Points = 'points',
  Experience = 'experience',
  AIRdrop = 'accessAIRdrop',
  RefFirstLVL = 'refFirstLevel',
  RefSecondLVL = 'refSecondLevel',
  RefThirdLVL = 'refThirdLevel',
}
