import React from 'react';
import { useNavigate } from 'react-router-dom';
// components
import { AdvancedBanner, IAdvancedBannerProps } from '../Advanced';
// styles
import backgroundPng from '../../../../../assets/bannerBgWithRays.png';
import titleLogoPng from '../../../../../assets/logoSmBingX.png';
// other
import { ROUTES } from '../../../../../../../constants';

interface IBingXAdvancedBannerProps extends Omit<IAdvancedBannerProps, 'title'> {
  description?: string | JSX.Element;
}

export const BingXAdvancedBanner: React.FC<IBingXAdvancedBannerProps> = bannerProps => {
  const navigate = useNavigate();

  const onOpen = () => navigate(`${ROUTES.bingX}`);

  return (
    <AdvancedBanner
      title='BingX'
      titleLogoSrc={titleLogoPng}
      bgImgSrc={backgroundPng}
      onClick={onOpen}
      {...bannerProps}
    />
  );
};
