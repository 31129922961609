import React from 'react';
// components
import { DailyRewardsBanner } from '../DailyRewards';
// import { MyChipsBanner } from '../MyChips';
// import { TapPadsBanner } from '../TapPads';
import { TadsBanner } from '../Tads';
import { BingXBanner } from '../BingX';
// styles
import styles from './styles.module.scss';

export const BannersList: React.FC = () => (
  <div className={styles.list}>
    <DailyRewardsBanner />
    {/* <MyChipsBanner /> */}
    {/* <TapPadsBanner /> */}
    <TadsBanner />
    <BingXBanner />
  </div>
);
