import React, { MouseEvent } from 'react';
// components
import { Box, Button, HStack, Tag, Text } from '@chakra-ui/react';
import { Icon } from '../../../../../components/Icon';
// styles
import styles from '../../../components/Tabs/styles.module.scss';
import bushImg from '../../../../../assets/images/bush.png';
import { IUpgrade } from '../../../types';

export interface ICardUpgrade extends IUpgrade {
  type: string;
  title: string;
  cargo: {
    from: string | null;
    to?: string | null;
  };
}

interface IUpgradeCardProps {
  upgrade: ICardUpgrade;
  onUpgrade: (upgrade: ICardUpgrade, event?: MouseEvent<HTMLButtonElement>) => void;
}

export const UpgradeCard: React.FC<IUpgradeCardProps> = ({ upgrade, onUpgrade }) => {
  const { level, title, price, cargo } = upgrade;

  return (
    <Box borderBottom='1px' borderColor='var(--border-bottom-color)' paddingY='12px'>
      <HStack justifyContent='space-between' alignItems='start'>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Text
            fontFamily="'Inter', sans-serif"
            color='var(--text-primary-color)'
            fontSize='16px'
            fontWeight='400'
            lineHeight='21.6px'
          >
            {title}
          </Text>
          <Box display='flex' alignItems='center' flexWrap='wrap' gap='8px'>
            <Tag gap='8px' padding='4px 8px 4px 8px' borderRadius='20px' fontWeight='500'>
              <img alt='' src={bushImg} width='15px' height='13px' /> Level {level}
            </Tag>
            <Text
              fontFamily="'Inter', sans-serif"
              color='var(--text-primary-color)'
              fontSize='13px'
              fontWeight='400'
            >
              {cargo?.from}
              {cargo?.to && (
                <Text color='#1717179e' as='span'>
                  {` → ${cargo?.to}`}
                </Text>
              )}
            </Text>
          </Box>
        </Box>
        {price && (
          <Button
            className={styles.btn}
            variant='solid'
            size='sm'
            backgroundColor='var(--bg-accent-color)'
            padding='10px 16px 10px 18px'
            onClick={e => onUpgrade(upgrade, e)}
            _hover={{ backgrounColor: 'var(--bg-accent-color)' }}
          >
            <Text color='white' display='flex' alignItems='center' gap='5px'>
              {Number(price).toFixed(2)} <Icon sizeInPx={16} />{' '}
            </Text>
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default UpgradeCard;
