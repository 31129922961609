import React from 'react';
// components
import { Image } from '@chakra-ui/react';
import { Drawer, IDrawerProps } from '../../../../../../components/Modal';
import { List } from '../../../../../../components/List';
// styles
import styles from './styles.module.scss';
// other
import { INftCardProps } from '../../Card';

export interface INftDrawerProps extends Omit<IDrawerProps, 'children'> {
  NFTs: Array<INftCardProps & { id: string }>;
}

export const NftDrawer: React.FC<INftDrawerProps> = ({ NFTs, ...drawerProps }) => (
  <Drawer classNames={{ drawer: styles.drawer }} {...drawerProps}>
    <List title='STAKED'>
      {NFTs.map(({ id, previewUrl, description }, idx) => (
        <div key={idx + id} className={styles.card}>
          <Image className={styles.image} src={previewUrl} width='44px' height='44px' />
          <p className={styles.description}>{description}</p>
        </div>
      ))}
    </List>
  </Drawer>
);
