import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
// components
import { Banner, IBannerProps } from '../Banner';
// styles
import diamondGif from '../../../../../Diamonds/assets/diamond.gif';
// other
import { API, fetcher } from '../../../../../../api';
import { ROUTES } from '../../../../../../constants';
import { Balance } from '../../../../types';

interface ITadsApiFeed {
  widgetId: string;
  enabled: true;
  rewardValue: string;
  rewardType: string;
}

interface ITadsBannerProps extends Omit<IBannerProps, 'title' | 'description' | 'image'> {
  description?: string | JSX.Element;
}

export const TadsBanner: React.FC<ITadsBannerProps> = bannerProps => {
  const navigate = useNavigate();

  const { data: tads, isLoading } = useSWR<ITadsApiFeed>(API.tads, fetcher);

  const getRewardIcon = (type?: string) => {
    switch (type) {
      case Balance.Main:
        return '💎';
      case Balance.Pink:
        return type;
      default:
        return '';
    }
  };

  const onOpen = () =>
    navigate(
      `${ROUTES.tads}?id=${tads?.widgetId}&claim=${tads?.rewardValue} ${getRewardIcon(tads?.rewardType)}`
    );

  return (
    <>
      {!isLoading && tads?.enabled && (
        <Banner
          title='Act 2 Earn'
          description='Complete tasks from partners'
          image={diamondGif}
          onClick={onOpen}
          {...bannerProps}
        />
      )}
    </>
  );
};
