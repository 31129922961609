export enum Quest {
  MyChips = 'myChips',
  Quiz = 'quiz',
  TapPads = 'tapPads',
  BingX = 'bingX',
  BybitConnection = 'bybitConnection',
  WalletConnection = 'walletConnection',
  Nomis = 'nomis',
}

export enum Status {
  Completed = 'completed',
  Checking = 'checking',
}
