import React from 'react';
import { ITagProps, Tag } from '../../../../../../../../components/Tag';
import styles from './styles.module.scss';

export enum TagType {
  New = 'new',
  Time = 'time',
}

interface IAdvancedBannerTagProps extends Omit<ITagProps, 'text'> {
  type: TagType;
  text?: string;
}

export const AdvancedBannerTag: React.FC<IAdvancedBannerTagProps> = ({ type = TagType.New, ...props }) => {
  const generateProperties = (type: TagType) => {
    switch (type) {
      case TagType.New:
        return { icon: '💫', text: 'New', className: styles.tagAccent };
      case TagType.Time:
        return { icon: '🕐', text: '', className: styles.tag };
    }
  };
  return <Tag {...generateProperties(type)} {...props} />;
};
