import React from 'react';
import { TonConnectButton, TonConnectButtonProps } from '@tonconnect/ui-react';
import styles from './styles.module.scss';

export interface ITonWalletButtonProps extends TonConnectButtonProps {
  onClick?: (event: Event) => void;
}

export const TonWalletButton: React.FC<ITonWalletButtonProps> = connectBtnProps => (
  <TonConnectButton className={styles.tonConnectBtn} {...connectBtnProps} />
);

export default TonWalletButton;
