import React from 'react';
// components
import { Image } from '@chakra-ui/react';
// styles
import styles from './styles.module.scss';

export interface INftCardProps {
  description: string;
  previewUrl?: string;
}

export const NftCard: React.FC<INftCardProps> = ({ description, previewUrl }) => (
  <div className={styles.wrapper}>
    <Image className={styles.image} src={previewUrl} width='180px' height='180px' loading='lazy' />
    <p className={styles.description}>{description}</p>
  </div>
);
