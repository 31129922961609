import React from 'react';
// styles
import diamondSvg from '../../../../../../assets/images/diamond.svg';
import pinkHeartSvg from '../../../../../../assets/images/pinkHeart.svg';
import styles from './styles.module.scss';
import { Image } from '@chakra-ui/react';

export interface IBingXHeaderProps {
  ballance: string;
  pinkBallance: string;
}

export const BingXHeader: React.FC<IBingXHeaderProps> = ({ ballance, pinkBallance }) => (
  <header className={styles.wrapper}>
    <p className={styles.ballance}>
      <Image src={diamondSvg} />
      <span>{Number(ballance).toFixed(2)}</span>
    </p>
    <p className={styles.ballance}>
      <span>{Number(pinkBallance).toFixed()}</span>
      <Image src={pinkHeartSvg} />
    </p>
  </header>
);
