import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';

export const OnClickaBanner: React.FC = () => {
  const banner = useRef<HTMLDivElement>(null);
  const id = '6033975';

  useEffect(() => {
    if (!banner.current || !window.ocMan) return;
    const spot = window.ocMan?.adFormats
      ?.find((b: any) => b.type === 'banner')
      ?.spots.find((s: any) => s.config.spot_id == id);

    if (spot && !banner.current?.childNodes?.length) window.ocMan.runFormatSpot('banner', spot.config);
  }, []);

  return <div className={styles.onClickaBanner} ref={banner} data-banner-id={id}></div>;
};
