import React from 'react';
// components
import { Image } from '@chakra-ui/react';
import { IProgress, IProgressBarProps } from '../../../../../../components/ProgressBar';
import { AdvancedBannerProgress, AdvancedBannerTag, TagType } from './components';
// styles
import diamondSvg from '../../../../../../assets/images/diamond.svg';
import pinkHeartSvg from '../../../../../../assets/images/pinkHeart.svg';
import styles from './styles.module.scss';

export interface IAvailableReward {
  text?: string;
  main?: number;
  pink?: number;
}

export interface IAdvancedBannerConfig {
  progress?: IProgress;
  availableReward?: IAvailableReward;
  duration?: string;
  disabled?: boolean;
  showAlert?: boolean;
  small?: boolean;
  isNew?: boolean;
  onClick?: () => void;
}

type AdvancedBannerExtendsProps = IAdvancedBannerConfig &
  Omit<IProgressBarProps, 'title' | 'progress' | 'onClick'>;

export interface IAdvancedBannerProps extends AdvancedBannerExtendsProps {
  logo?: string | JSX.Element;
  title: string | JSX.Element;
  titleLogoSrc?: string;
  bgImgSrc?: string;
}

export const AdvancedBanner: React.FC<IAdvancedBannerProps> = ({
  logo,
  title,
  titleLogoSrc,
  bgImgSrc,
  progress,
  availableReward,
  duration,
  disabled,
  showAlert,
  small,
  isNew,
  onClick,
  ...progressBarProps
}) => {
  const showRewards = availableReward?.main || availableReward?.pink;

  return (
    <div
      className={`${disabled ? styles.wrapperDisabled : styles.wrapper} ${small && styles.wrapperSmall}`}
      onClick={onClick}
    >
      {bgImgSrc && <Image className={styles.wrapperBackground} src={bgImgSrc} />}
      <div className={showAlert ? styles.contentWithAlert : styles.content}>
        <div className={styles.contentMain}>
          <div className={styles.rowBetween}>
            <h3 className={styles.title}>
              {titleLogoSrc && <Image className={styles?.titleLogo} src={titleLogoSrc} />}
              {title}
            </h3>
            <div className={styles.tags}>
              {isNew && <AdvancedBannerTag type={TagType.New} />}
              {duration && <AdvancedBannerTag type={TagType.Time} text={duration} />}
            </div>
          </div>
          {typeof logo === 'string' ? (
            <Image className={styles?.image} src={logo} width='170' height='170' />
          ) : (
            logo
          )}
        </div>
        <div className={styles.footer}>
          <div className={showRewards ? styles.progressWithRewards : styles.progress}>
            {progress && <AdvancedBannerProgress progress={progress} {...progressBarProps} />}
          </div>
          {showRewards && (
            <div className={styles.rewards}>
              <h5 className={styles.rewardsTitle}>{availableReward?.text || 'Available'}</h5>
              <p className={styles.rewardsDescription}>
                {!!availableReward.main && (
                  <span>
                    <Image className={styles.rewardsIcon} src={diamondSvg} />
                    <span>{availableReward.main}</span>
                  </span>
                )}
                {!!availableReward.pink && (
                  <span>
                    <Image className={styles.rewardsIcon} src={pinkHeartSvg} />
                    <span>{availableReward.pink}</span>
                  </span>
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
