import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// components
import { Layout } from '../../../components/Layout';
import { TadsFeeds } from '../components/Quests';
// other
import { ROUTES } from '../../../constants';
import { useDarkToast, useQueryParams } from '../../../hooks';
import { API, loggedAxios } from '../../../api';

const checkInterval = 5000; // 5 seconds

export const Tads: React.FC = () => {
  const navigation = useNavigate();
  const { showToast } = useDarkToast();
  const { id, claim } = useQueryParams();

  const startTimeRef = useRef<number | null>(null);

  const handleReturnToApp = () => {
    if (startTimeRef.current) {
      const timeSpent = Date.now() - startTimeRef.current;

      if (timeSpent >= checkInterval) {
        try {
          loggedAxios.post(API.tadsClaim).catch(e => {
            console.error(e);
          });
          showToast(`YOU CLAIMED ${claim}`.toUpperCase());
        } catch (error) {
          console.error(error);
          showToast(`SOMETHING WENT WRONG`);
        }
      } else {
        showToast(`TASK ISN'T COMPLETED`);
      }

      startTimeRef.current = null;
    }
  };

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        handleReturnToApp();
      }
    };

    tg.onEvent('popupClosed', handleReturnToApp);
    tg.onEvent('viewportChanged', handleReturnToApp);
    window.addEventListener('focus', handleReturnToApp);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      tg.offEvent('popupClosed', handleReturnToApp);
      tg.offEvent('viewportChanged', handleReturnToApp);
      window.removeEventListener('focus', handleReturnToApp);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickReward = () => {
    startTimeRef.current = Date.now();
  };

  const handleClickBack = () => navigation(`${ROUTES.home}?tab=QUESTS`);

  return (
    <Layout>
      <div className='container flexColumn'>
        <TadsFeeds widgetId={id} onClickReward={handleClickReward} onClickBack={handleClickBack} />
      </div>
    </Layout>
  );
};
